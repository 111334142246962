import Honeybadger from '@honeybadger-io/js';

Honeybadger.configure({
  apiKey: '43fd386f',
  environment: 'production',
  revision: process.env.NODE_ENV
});

Honeybadger.setContext({
  user_id: window.honeybadger_context_id
});

const ignorePatterns = [
  /Unknown player. Probably unloaded/i,
  /Object Not Found Matching/i,
  /Can't find variable: ResizeObserver/i,
  /ResizeObserver is not defined/i,
  /Error: Network Error/i,
  /Request failed with status code 401/i,
  /read property 'getReadModeExtract'/i,
  /Illegal invocation/i,
  /Avoided redundant navigation/i,
  /Failed to fetch/i,
  /UnhandledPromiseRejectionWarning/i,
]
Honeybadger.beforeNotify(function(notice) {
  if (ignorePatterns.some((p) => p.test(notice.message))) { return false; }
});

export default Honeybadger;